import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { SimpleData } from "../api/dummyList";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  IconButton,
  List,
  ListItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { PreFormData } from "./CreatePreOrderForm";
import apiEndpoint from "../api/apiEndpoints";
import { SpecificOrderFormData } from "../components/SpecificFormStepper";
import { useNavigate } from "react-router-dom";

export const ListOrders = () => {
  const [orders, setOrders] = useState<Array<SimpleData> | []>([]);
  const [preOrders, setPreOrders] = useState<Array<PreFormData> | []>([]);
  const [specificOrders, setSpecificOrders] = useState<Array<any> | []>([]);
  const [date, setDate] = useState(null);
  const [filteredData, setFilteredData] = useState<Array<SimpleData> | []>([]); // Function to filter orders based on the selected date
  const [filteredPreOrder, setFilteredPreOrder] = useState<
    Array<PreFormData> | []
  >([]);
  const [filteredSpecificOrder, setFilteredSpecificOrder] = useState<
    Array<SpecificOrderFormData> | []
  >([]);
  const [showAll, setShowAll] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [showPreMessage, setShowPreMessage] = useState(false);
  const [showSpecificMessage, setShowSpecificMessage] = useState(false);
  const [selectedId, setSelectedId] = useState<string | number | null>();
  const [refName, setRefName] = useState("");
  const navigate = useNavigate();

  const data = showAll ? orders : filteredData;
  const preOrdersData = showAll ? preOrders : filteredPreOrder;
  const specificOrdersData = showAll ? specificOrders : filteredSpecificOrder;

  const getNormalOrders = useCallback(() => {
    fetch(`${apiEndpoint}read`) // Replace '/api/items' with your actual API endpoint
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setOrders(data); // Update the state with the fetched data
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }, []);
  useEffect(() => {
    getNormalOrders();
  }, [getNormalOrders]);
  const getPreOrders = useCallback(() => {
    fetch(`${apiEndpoint}preorders`) // Replace '/api/items' with your actual API endpoint
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setPreOrders(data); // Update the state with the fetched data
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }, []);
  useEffect(() => {
    getPreOrders();
  }, [getPreOrders]);
  const getSpecificOrders = useCallback(() => {
    fetch(`${apiEndpoint}specificforms`) // Replace '/api/items' with your actual API endpoint
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setSpecificOrders(data); // Update the state with the fetched data
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }, []);
  useEffect(() => {
    getSpecificOrders();
  }, [getSpecificOrders]);
  const handleDelete = (id: string | number) => {
    fetch(`${apiEndpoint}delete/${id}`, { method: "DELETE" })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Can not delete");
        }
        return response.json();
      })
      .then((data) => {
        setShowAlert(true);
        getNormalOrders();
      })
      .catch((error) => {
        console.error("There was a problem with deleting data:", error);
      });
  };
  const handlePreOrderDelete = (id: string | number) => {
    fetch(`${apiEndpoint}deletepreorder/${id}`, { method: "DELETE" })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Can not delete");
        }
        return response.json();
      })
      .then((data) => {
        setShowAlert(true);
        getPreOrders();
      })
      .catch((error) => {
        console.error("There was a problem with deleting data:", error);
      });
  };
  const handleSpecificOrderDelete = (id: string | number) => {
    fetch(`${apiEndpoint}deletespecificorder/${id}`, { method: "DELETE" })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Can not delete");
        }
        return response.json();
      })
      .then((data) => {
        setShowAlert(true);
        getSpecificOrders();
      })
      .catch((error) => {
        console.error("There was a problem with deleting data:", error);
      });
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowAlert(false);
    }, 3000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [showAlert]);
  return (
    <Stack width="75%" m="auto" mt={3}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <DatePicker
          label="Form Date"
          value={date} // Use a default date if selectDay is null
          onChange={(date) => {
            setDate(date);

            setFilteredData(
              orders.filter(
                (order) =>
                  dayjs(order.selectedDate).format("DD/MM/YYYY") ===
                  dayjs(date).format("DD/MM/YYYY")
              )
            );
            setFilteredPreOrder(
              preOrders.filter(
                (order) =>
                  dayjs(order.selectedDate).format("DD/MM/YYYY") ===
                  dayjs(date).format("DD/MM/YYYY")
              )
            );
            setFilteredSpecificOrder(
              specificOrders.filter(
                (order) =>
                  dayjs(order.selectedDate).format("DD/MM/YYYY") ===
                  dayjs(date).format("DD/MM/YYYY")
              )
            );
            setShowAll(false);
          }}
          format="DD/MM/YYYY" // Update selectDay when a new date is selected
        />
        <Stack direction="row" width="300px">
          <TextField
            size="small"
            label="Search REF Name"
            value={refName}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setRefName(event.target.value);
              setFilteredData(
                orders.filter((order) =>
                  order.customer.refName
                    .toLowerCase()
                    .includes(event.target.value.toLowerCase())
                )
              );
              setFilteredPreOrder(
                preOrders.filter((order) =>
                  order.refName
                    .toLowerCase()
                    .includes(event.target.value.toLowerCase())
                )
              );
              setFilteredSpecificOrder(
                specificOrders.filter((order) =>
                  order.customer.refName
                    .toLowerCase()
                    .includes(event.target.value.toLowerCase())
                )
              );
              setDate(null);
              setShowAll(false);
            }}
          />
        </Stack>
        <Button
          onClick={() => {
            setDate(null);
            setRefName("");
            setShowAll(true);
          }}
        >
          See All Data
        </Button>
      </Stack>
      <List>
        <Typography variant="h6">Normal Orders</Typography>
        <Divider flexItem />
        {data.length > 0 ? (
          data.map((o) => {
            return (
              <List
                key={o.id}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  padding: 0,
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#e9e9e9",
                    transition: "ease all 0.2s", // Change this to your desired hover color
                  },
                }}
              >
                <ListItem>
                  {dayjs(o.selectedDate).format("DD/MM/YYYY")}
                </ListItem>
                <ListItem>{o.customer.refName}</ListItem>
                <ListItem>{o.customer.city}</ListItem>
                <ListItem>
                  <EditIcon
                    sx={{ color: "blue", mx: 2 }}
                    onClick={() => navigate(`/admin/editnormalform/${o.id}`)}
                  />
                  <DeleteForeverIcon
                    sx={{ color: "red" }}
                    onClick={() => {
                      setShowMessage(true);
                      setSelectedId(o.id);
                    }}
                  />
                </ListItem>

                <Dialog
                  fullWidth
                  open={showMessage}
                  onClose={() => setShowMessage(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Do you really want to delete?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setShowMessage(false)}>
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        selectedId && handleDelete(selectedId);
                        setShowMessage(false);
                      }}
                      autoFocus
                    >
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </List>
            );
          })
        ) : (
          <>There is no data today</>
        )}
      </List>
      <List>
        <Typography variant="h6">Specific Orders</Typography>
        <Divider flexItem />
        {specificOrdersData.length > 0 ? (
          specificOrdersData.map((o) => {
            return (
              <List
                key={o.id}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  padding: 0,
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#e9e9e9",
                    transition: "ease all 0.2s", // Change this to your desired hover color
                  },
                }}
              >
                <ListItem>
                  {dayjs(o.selectedDate).format("DD/MM/YYYY")}
                </ListItem>
                <ListItem>{o.customer.refName}</ListItem>
                <ListItem>{o.customer.city}</ListItem>
                <ListItem>
                  <EditIcon
                    sx={{ color: "blue", mx: 2 }}
                    onClick={() => navigate(`/admin/editspecificform/${o.id}`)}
                  />
                  <DeleteForeverIcon
                    sx={{ color: "red" }}
                    onClick={() => {
                      setShowSpecificMessage(true);
                      setSelectedId(o.id);
                    }}
                  />
                </ListItem>

                <Dialog
                  fullWidth
                  open={showSpecificMessage}
                  onClose={() => setShowSpecificMessage(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Do you really want to delete?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setShowSpecificMessage(false)}>
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        selectedId && handleSpecificOrderDelete(selectedId);
                        setShowSpecificMessage(false);
                      }}
                      autoFocus
                    >
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </List>
            );
          })
        ) : (
          <>There is no data today</>
        )}
      </List>

      <List>
        <Typography variant="h6">Pre Orders</Typography>
        <Divider flexItem />
        {preOrdersData.length > 0 ? (
          preOrdersData.map((o) => {
            return (
              <List
                key={o.id}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  padding: 0,
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#e9e9e9",
                    transition: "ease all 0.2s", // Change this to your desired hover color
                  },
                }}
              >
                <ListItem>
                  {dayjs(o.selectedDate).format("DD/MM/YYYY")}
                </ListItem>
                <ListItem>{o.refName}</ListItem>
                <ListItem>{o.city}</ListItem>
                <ListItem>
                  <DeleteForeverIcon
                    sx={{ color: "red" }}
                    onClick={() => {
                      setShowPreMessage(true);
                      setSelectedId(o.id);
                    }}
                  />
                </ListItem>

                <Dialog
                  fullWidth
                  open={showPreMessage}
                  onClose={() => setShowPreMessage(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Do you really want to delete?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setShowPreMessage(false)}>
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        selectedId && handlePreOrderDelete(selectedId);
                        setShowPreMessage(false);
                      }}
                      autoFocus
                    >
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </List>
            );
          })
        ) : (
          <>There is no data today</>
        )}
      </List>
      {showAlert && (
        <Alert
          severity="success"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setShowAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Successfully deleted!
        </Alert>
      )}
    </Stack>
  );
};
