import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { ChangeEvent, useState } from "react";
import TextField from "@mui/material/TextField";
import { Grid, Stack, Switch, Typography } from "@mui/material";
import { FormData } from "./Stepper";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

type Props = {
  formData: FormData;
  setFormData: (form: FormData) => void;
};

function UserForm({ formData, setFormData }: Props) {
  const [checked, setChecked] = useState(false);

  const handleFieldChange = (
    fieldName: keyof FormData,
    value: any
  ) => {
    setFormData({
      ...formData,
      [fieldName]: fieldName==="selectedDate"?value.toISOString():value,
    });
  };
  return (
    <form>
      <Grid container direction="row" spacing={1} mt={0.5} alignItems="center">
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Ref Name"
            variant="outlined"
            value={formData.refName}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleFieldChange("refName", e.target.value)
            }
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            sx={{ width: "100%" }}
            label="Form Date"
            value={dayjs(formData.selectedDate)}
            onChange={(date: Date | Dayjs | null) =>
              handleFieldChange("selectedDate", date)
            }
            format="DD/MM/YYYY"
          />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label="Period 10:00-12:00"
            variant="outlined"
            value={formData.period}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleFieldChange("period", e.target.value)
            }
          />
        </Grid>
      </Grid>

      <Stack my={1} border="solid 1px #d9d9d9">
        {/* <Typography variant="subtitle1" sx={{ ml: 2, mt: 1 }}>
          Address
          </Typography>*/}
        <Stack p={1}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <TextField
              fullWidth
              label="Client Name"
              variant="outlined"
              value={formData.clientName}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleFieldChange("clientName", e.target.value)
              }
            />
            <Stack direction="column" alignItems="center">
              <Typography fontWeight={600}>Gripp</Typography>{" "}
              <Switch
                color="warning"
                checked={checked}
                onChange={(e) => {
                  setChecked(e.target.checked);
                  handleFieldChange("isGripp", e.target.checked);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Stack>
          </Stack>

          <Stack direction="row" alignItems="center" spacing={2} my={1}>
            <TextField
              label="Street"
              variant="outlined"
              fullWidth
              value={formData.street}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleFieldChange("street", e.target.value)
              }
            />
            <TextField
              label="House Number"
              variant="outlined"
              fullWidth
              value={formData.houseNumber}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleFieldChange("houseNumber", e.target.value)
              }
            />
            <TextField
              label="Postcode"
              variant="outlined"
              fullWidth
              value={formData.postcode}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleFieldChange("postcode", e.target.value)
              }
            />
            <TextField
              label="City"
              variant="outlined"
              fullWidth
              value={formData.city}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleFieldChange("city", e.target.value)
              }
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack direction="row" spacing={2}>
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          value={formData.email}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleFieldChange("email", e.target.value)
          }
        />
        <TextField
          label="Phone Number"
          variant="outlined"
          fullWidth
          value={formData.phoneNumber}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleFieldChange("phoneNumber", e.target.value)
          }
        />
      </Stack>
      <TextField
        sx={{ mt: 0.5 }}
        fullWidth
        multiline
        minRows={2}
        label="User Note"
        variant="outlined"
        value={formData.userNote}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          handleFieldChange("userNote", e.target.value)
        }
      />
    </form>
  );
}

export default UserForm;
