import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import UserForm from "./UserForm";
import { useEffect, useState } from "react";
import { Alert, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import apiEndpoint from "../api/apiEndpoints";
import SpecificOrderForm from "./SpecificOrderForm";
import dayjs, { Dayjs } from "dayjs";
const steps = ["Client Informations", "Order Form"];
export interface SpecificFormData {
  clientName: string;
  refName: string;
  isGripp?: boolean;
  street: string;
  houseNumber: string;
  postcode: string;
  city: string;
  email: string;
  phoneNumber: string;
  selectedDate: Date | Dayjs | null;
  period: string;
  dateNote: string;
  userNote: string;
}
export interface Compleet {
  spoor: { value: string };
  h: string;
  w: string;
}

export interface SpecificCam {
  amount: string;
  note: string;
  h: { value: string } | null;
  w: { value: string } | null;
  type?: string | null;
}

export interface SpecificOrderFormData {
  compleet1: Compleet;
  compleet2: Compleet;
  compleet3: Compleet;
  compleet4: Compleet;
  compleet5: Compleet;
  compleet6: Compleet;
  compleet7: Compleet;
  compleet8: Compleet;
  cam1: SpecificCam;
  cam2: SpecificCam;
  cam3: SpecificCam;
  cam4: SpecificCam;
  cam5: SpecificCam;
  cam6: SpecificCam;
  cam7: SpecificCam;
  cam8: SpecificCam;
  colorCode: { value: string };
  ts: string;
  hv: string;
  note: string;
  price: string;
}
export default function SpecificFormStepper() {
  const navigate = useNavigate();

  let initialState = {
    refName: "",
    clientName: "",
    isGripp: false,
    street: "",
    houseNumber: "",
    postcode: "",
    city: "",
    email: "",
    phoneNumber: "",
    selectedDate: dayjs(),
    period: "",
    dateNote: "",
    userNote: "",
  };

  const [formData, setFormData] = useState<SpecificFormData>(initialState);
  const [orderFormData, setOrderFormData] = useState<SpecificOrderFormData>({
    compleet1: { spoor: { value: "" }, h: "", w: "" },
    compleet2: { spoor: { value: "" }, h: "", w: "" },
    compleet3: { spoor: { value: "" }, h: "", w: "" },
    compleet4: { spoor: { value: "" }, h: "", w: "" },
    compleet5: { spoor: { value: "" }, h: "", w: "" },
    compleet6: { spoor: { value: "" }, h: "", w: "" },
    compleet7: { spoor: { value: "" }, h: "", w: "" },
    compleet8: { spoor: { value: "" }, h: "", w: "" },
    cam1: {
      amount: "",
      note: "",
      h: { value: "" },
      w: { value: "" },
      type: null,
    },
    cam2: {
      amount: "",
      note: "",
      h: { value: "" },
      w: { value: "" },
      type: null,
    },
    cam3: {
      amount: "",
      note: "",
      h: { value: "" },
      w: { value: "" },
      type: null,
    },
    cam4: {
      amount: "",
      note: "",
      h: { value: "" },
      w: { value: "" },
      type: null,
    },
    cam5: {
      amount: "",
      note: "",
      h: { value: "" },
      w: { value: "" },
      type: null,
    },
    cam6: {
      amount: "",
      note: "",
      h: { value: "" },
      w: { value: "" },
      type: null,
    },
    cam7: {
      amount: "",
      note: "",
      h: { value: "" },
      w: { value: "" },
      type: null,
    },
    cam8: {
      amount: "",
      note: "",
      h: { value: "" },
      w: { value: "" },
      type: null,
    },
    colorCode: { value: "" },
    ts: "",
    hv: "",
    note: "",
    price: "",
  });
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const [showAlert, setShowAlert] = useState(false);
  const dataToSend = {
    formData: formData,
    orderFormData: orderFormData,
  };
  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
  const handleSave = () => {
    fetch(`${apiEndpoint}createspecificform`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataToSend), // Serialize the dataToSend object to JSON
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Handle the response from the server if needed
        setShowAlert(true);
        setTimeout(() => navigate("/user"), 1200);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  useEffect(() => {
    setFormData({
      refName: "",
      clientName: "",
      isGripp: false,
      street: "",
      houseNumber: "",
      postcode: "",
      city: "",
      email: "",
      phoneNumber: "",
      selectedDate: dayjs(),
      period: "",
      dateNote: "",
      userNote: "",
    });
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        activeStep={activeStep}
        sx={{
          border: "solid 1px #ddd",
          py: 2,
          px: 2,
          backgroundColor: "#F9F9F9",
        }}
      >
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};

          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </>
      ) : (
        <>
          {activeStep === 0 && (
            <UserForm formData={formData} setFormData={setFormData} />
          )}
          {activeStep === 1 && (
            <SpecificOrderForm
              orderFormData={orderFormData}
              setOrderFormData={setOrderFormData}
            />
          )}
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />

            <Button
              disabled={
                formData.refName === "" || formData.selectedDate === null
              }
              onClick={
                activeStep === steps.length - 1 ? handleSave : handleNext
              }
            >
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Box>
        </>
      )}
      {showAlert && (
        <Alert
          severity="success"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setShowAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Successfully created!
        </Alert>
      )}
    </Box>
  );
}
