import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import UserForm from "./UserForm";
import { useCallback, useEffect, useState } from "react";
import { Alert, IconButton } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import apiEndpoint from "../api/apiEndpoints";
import { SpecificOrderFormData } from "./SpecificFormStepper";
import EditNormalOrderForm from "./EditNormalOrderForm";
import EditSpecificOrderForm from "./EditSpecificOrderForm";
import { FormData } from "./Stepper";
const steps = ["Client Informations", "Order Form"];

export interface Compleet {
  spoor: { value: string };
  h: string;
  w: string;
}

export interface Cam {
  amount: string;
  note: string;
  h: { value: string } | null;
  w: { value: string } | null;
}

export interface OrderFormData {
  compleet1: Compleet;
  compleet2: Compleet;
  compleet3: Compleet;
  compleet4: Compleet;
  compleet5: Compleet;
  compleet6: Compleet;
  compleet7: Compleet;
  compleet8: Compleet;
  cam1: Cam;
  cam2: Cam;
  cam3: Cam;
  cam4: Cam;
  cam5: Cam;
  cam6: Cam;
  cam7: Cam;
  cam8: Cam;
  colorCode: { value: string };
  ts: string;
  hv: string;
  note: string;
  price: string;
}
export default function EditFormStepper() {
  const { id } = useParams();
  const location = useLocation();
  const [formData, setFormData] = useState<FormData>();
  const [orderFormData, setOrderFormData] = useState<
    OrderFormData | SpecificOrderFormData
  >();
  const isNormalFormEdit = location.pathname.includes("editnormalform");
  const formUrl = isNormalFormEdit ? "read" : "specific";
  const handleUpdateUrl = isNormalFormEdit
    ? "updatenormalform"
    : "updatespecificform";
  const getForm = useCallback(() => {
    id &&
      fetch(`${apiEndpoint}${formUrl}/${id}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setFormData({
            refName: data.customer?.refName,
            clientName: data.customer?.clientName,
            street: data.customer?.street,
            houseNumber: data.customer?.houseNumber,
            postcode: data.customer?.postcode,
            isGripp: data.customer?.isGripp,
            city: data.customer?.city,
            email: data.customer?.email,
            phoneNumber: data.customer?.phoneNumber,
            selectedDate: new Date(data.selectedDate),
            period: data.period,
            dateNote: data.dateNote,
            userNote: data.customer?.userNote,
          });
          isNormalFormEdit
            ? setOrderFormData({
                compleet1: {
                  spoor: { value: data.order.compleet1.spoor },
                  h: data.order.compleet1.h,
                  w: data.order.compleet1.w,
                },
                compleet2: {
                  spoor: { value: data.order.compleet2.spoor },
                  h: data.order.compleet2.h,
                  w: data.order.compleet2.w,
                },
                compleet3: {
                  spoor: { value: data.order.compleet3.spoor },
                  h: data.order.compleet3.h,
                  w: data.order.compleet3.w,
                },
                compleet4: {
                  spoor: { value: data.order.compleet4.spoor },
                  h: data.order.compleet4.h,
                  w: data.order.compleet4.w,
                },
                compleet5: {
                  spoor: { value: data.order.compleet5.spoor },
                  h: data.order.compleet5.h,
                  w: data.order.compleet5.w,
                },
                compleet6: {
                  spoor: { value: data.order.compleet6.spoor },
                  h: data.order.compleet6.h,
                  w: data.order.compleet6.w,
                },
                compleet7: {
                  spoor: { value: data.order.compleet7.spoor },
                  h: data.order.compleet7.h,
                  w: data.order.compleet7.w,
                },
                compleet8: {
                  spoor: { value: data.order.compleet8.spoor },
                  h: data.order.compleet8.h,
                  w: data.order.compleet8.w,
                },
                cam1: {
                  amount: data.order.cam1.amount,
                  h: { value: data.order.cam1.h },
                  note: data.order.cam1.note,
                  w: { value: data.order.cam1.w },
                },
                cam2: {
                  amount: data.order.cam2.amount,
                  h: { value: data.order.cam2.h },
                  note: data.order.cam2.note,
                  w: { value: data.order.cam2.w },
                },
                cam3: {
                  amount: data.order.cam3.amount,
                  h: { value: data.order.cam3.h },
                  note: data.order.cam3.note,
                  w: { value: data.order.cam3.w },
                },
                cam4: {
                  amount: data.order.cam4.amount,
                  h: { value: data.order.cam4.h },
                  note: data.order.cam4.note,
                  w: { value: data.order.cam4.w },
                },
                cam5: {
                  amount: data.order.cam5.amount,
                  h: { value: data.order.cam5.h },
                  note: data.order.cam5.note,
                  w: { value: data.order.cam5.w },
                },
                cam6: {
                  amount: data.order.cam6.amount,
                  h: { value: data.order.cam6.h },
                  note: data.order.cam6.note,
                  w: { value: data.order.cam6.w },
                },
                cam7: {
                  amount: data.order.cam7.amount,
                  h: { value: data.order.cam7.h },
                  note: data.order.cam7.note,
                  w: { value: data.order.cam7.w },
                },
                cam8: {
                  amount: data.order.cam8.amount,
                  h: { value: data.order.cam8.h },
                  note: data.order.cam8.note,
                  w: { value: data.order.cam8.w },
                },
                colorCode: {
                  value: data.order.colorCode.value,
                },
                ts: data.order.ts,
                hv: data.order.hv,
                note: data.order.note,
                price: data.order.price,
              })
            : setOrderFormData({
                compleet1: {
                  spoor: { value: data.order.compleet1.spoor },
                  h: data.order.compleet1.h,
                  w: data.order.compleet1.w,
                },
                compleet2: {
                  spoor: { value: data.order.compleet2.spoor },
                  h: data.order.compleet2.h,
                  w: data.order.compleet2.w,
                },
                compleet3: {
                  spoor: { value: data.order.compleet3.spoor },
                  h: data.order.compleet3.h,
                  w: data.order.compleet3.w,
                },
                compleet4: {
                  spoor: { value: data.order.compleet4.spoor },
                  h: data.order.compleet4.h,
                  w: data.order.compleet4.w,
                },
                compleet5: {
                  spoor: { value: data.order.compleet5.spoor },
                  h: data.order.compleet5.h,
                  w: data.order.compleet5.w,
                },
                compleet6: {
                  spoor: { value: data.order.compleet6.spoor },
                  h: data.order.compleet6.h,
                  w: data.order.compleet6.w,
                },
                compleet7: {
                  spoor: { value: data.order.compleet7.spoor },
                  h: data.order.compleet7.h,
                  w: data.order.compleet7.w,
                },
                compleet8: {
                  spoor: { value: data.order.compleet8.spoor },
                  h: data.order.compleet8.h,
                  w: data.order.compleet8.w,
                },
                cam1: {
                  amount: data.order.cam1.amount,
                  h: { value: data.order.cam1.h },
                  note: data.order.cam1.note,
                  w: { value: data.order.cam1.w },
                  type: data.order.cam1.type,
                },
                cam2: {
                  amount: data.order.cam2.amount,
                  h: { value: data.order.cam2.h },
                  note: data.order.cam2.note,
                  w: { value: data.order.cam2.w },
                  type: data.order.cam2.type,
                },
                cam3: {
                  amount: data.order.cam3.amount,
                  h: { value: data.order.cam3.h },
                  note: data.order.cam3.note,
                  w: { value: data.order.cam3.w },
                  type: data.order.cam3.type,
                },
                cam4: {
                  amount: data.order.cam4.amount,
                  h: { value: data.order.cam4.h },
                  note: data.order.cam4.note,
                  w: { value: data.order.cam4.w },
                  type: data.order.cam4.type,
                },
                cam5: {
                  amount: data.order.cam5.amount,
                  h: { value: data.order.cam5.h },
                  note: data.order.cam5.note,
                  w: { value: data.order.cam5.w },
                  type: data.order.cam5.type,
                },
                cam6: {
                  amount: data.order.cam6.amount,
                  h: { value: data.order.cam6.h },
                  note: data.order.cam6.note,
                  w: { value: data.order.cam6.w },
                  type: data.order.cam6.type,
                },
                cam7: {
                  amount: data.order.cam7.amount,
                  h: { value: data.order.cam7.h },
                  note: data.order.cam7.note,
                  w: { value: data.order.cam7.w },
                  type: data.order.cam7.type,
                },
                cam8: {
                  amount: data.order.cam8.amount,
                  h: { value: data.order.cam8.h },
                  note: data.order.cam8.note,
                  w: { value: data.order.cam8.w },
                  type: data.order.cam8.type,
                },
                colorCode: {
                  value: data.order.colorCode.value,
                },
                ts: data.order.ts,
                hv: data.order.hv,
                note: data.order.note,
                price: data.order.price,
              });
        })
        .catch((error) => {
          console.error("There was a problem with the fetch operation:", error);
        });
  }, [formUrl, id, isNormalFormEdit]);

  useEffect(() => {
    getForm();
  }, [getForm]);
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const [showAlert, setShowAlert] = useState(false);
  const dataToSend = {
    formData: formData,
    orderFormData: orderFormData,
  };
  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
  const handleSave = () => {
    fetch(`${apiEndpoint}${handleUpdateUrl}/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataToSend), // Serialize the dataToSend object to JSON
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Handle the response from the server if needed
        setShowAlert(true);
        setTimeout(() => navigate("/user"), 1200);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        activeStep={activeStep}
        sx={{
          border: "solid 1px #ddd",
          py: 2,
          px: 2,
          backgroundColor: "#F9F9F9",
        }}
      >
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};

          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </>
      ) : (
        <>
          {activeStep === 0 && formData && (
            <UserForm formData={formData} setFormData={setFormData} />
          )}
          {activeStep === 1 && orderFormData && isNormalFormEdit && (
            <EditNormalOrderForm
              orderFormData={orderFormData}
              setOrderFormData={setOrderFormData}
            />
          )}
          {activeStep === 1 && orderFormData && !isNormalFormEdit && (
            <EditSpecificOrderForm
              orderFormData={orderFormData}
              setOrderFormData={setOrderFormData}
            />
          )}
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />

            <Button
              disabled={
                formData?.refName === "" || formData?.selectedDate === null
              }
              onClick={
                activeStep === steps.length - 1 ? handleSave : handleNext
              }
            >
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Box>
        </>
      )}
      {showAlert && (
        <Alert
          severity="success"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setShowAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Successfully updated!
        </Alert>
      )}
    </Box>
  );
}
