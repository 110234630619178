import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  Divider,
  Grid,
  Snackbar,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import apiEndpoint from "../api/apiEndpoints";

export const UserDetail = () => {
  const small = useMediaQuery("(min-width:1280px)");
  const [userData, setUserData] = useState<any>();
  const [userRole, setUserRole] = useState<string>();
  const [notification, setNotification] = useState<{
    isOpen: boolean;
    message: string;
  }>({ isOpen: false, message: "" });
  const [xClicked, setxClicked] = useState(false);
  const [isFinanced, setIsFinanced] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const getRecords = useCallback(() => {
    fetch(`${apiEndpoint}read/${id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setUserData(data); // Update the state with the fetched data
      })
      .catch((error) => {
        setNotification({ isOpen: true, message: "Server Error" });
      });
  }, [id]);
  useEffect(() => {
    getRecords();
  }, [getRecords]);
  useEffect(() => {
    const storedRole = sessionStorage.getItem("role");
    storedRole && setUserRole(storedRole);
  }, []);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setNotification({ message: "", isOpen: false });
  };

  const exportPDF = async () => {
    const content = document.getElementById("export");

    if (!content) {
      return;
    }

    const pdfContainer = (
      await html2canvas(content, { scale: 0.72 })
    ).toDataURL("image/png");
    const pdf = new jsPDF("p", "px", "a4");
    pdf.addImage(pdfContainer, "PNG", 5, 0, 0, 0);
    pdf.save("download.pdf");

    fetch(`${apiEndpoint}update_printed/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ isprinted: true }),
    })
      .then((response) => {
        if (response.status === 200) {
          setNotification({
            isOpen: true,
            message: "isprinted value updated successfully",
          });
        } else if (response.status === 404) {
          setNotification({ isOpen: true, message: "Record not found" });
        } else {
          setNotification({
            isOpen: true,
            message: "Failed to update isprinted value",
          });
        }
      })
      .catch((error) => {
        setNotification({ isOpen: true, message: "Server Error" });
      });
  };
  const handleFinanced = () => {
    fetch(`${apiEndpoint}update_isfinanced/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ isfinanced: true }),
    })
      .then((response) => {
        if (response.status === 200) {
          setNotification({
            isOpen: true,
            message: "isprinted value updated successfully",
          });
          setIsFinanced(true);
          setNotification({ isOpen: true, message: "Successfully approved" });
        } else if (response.status === 404) {
          setNotification({ isOpen: true, message: "Record not found" });
        } else {
          setNotification({
            isOpen: true,
            message: "Failed to update isprinted value",
          });
        }
      })
      .catch((error) => {
        setNotification({ isOpen: true, message: "Server Error" });
      });
  };
  const handleX = () => {
    fetch(`${apiEndpoint}update_isx/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ isx: true }),
    })
      .then((response) => {
        if (response.status === 200) {
          setNotification({
            isOpen: true,
            message: "isprinted value updated successfully",
          });
          setxClicked(true);
          setNotification({ isOpen: true, message: "Successfully approved" });
        } else if (response.status === 404) {
          setNotification({ isOpen: true, message: "Record not found" });
        } else {
          setNotification({
            isOpen: true,
            message: "Failed to update isprinted value",
          });
        }
      })
      .catch((error) => {
        setNotification({ isOpen: true, message: "Server Error" });
      });
  };

  const handleCompleted = () => {
    fetch(`${apiEndpoint}update_iscompleted/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ iscompleted: true }),
    })
      .then((response) => {
        if (response.status === 200) {
          setIsFinished(true);
          setNotification({ isOpen: true, message: "Successfully completed" });
        } else if (response.status === 404) {
          setNotification({ isOpen: true, message: "Record not found" });
        } else {
          setNotification({
            isOpen: true,
            message: "Failed to update isprinted value",
          });
        }
      })
      .catch((error) => {
        setNotification({ isOpen: true, message: "Server Error" });
      });
  };

  const handleRefresh = () => {
    window.location.reload();
  };
  return (
    <Grid
      container
      m="auto"
      my={0.5}
      p={1}
      sx={{ backgroundColor: "#f1f1f1" }}
      direction="column"
      item
      xs={12}
      sm={12}
      md={10}
      lg={8}
    >
      <Breadcrumbs aria-label="breadcrumb" sx={{ justifyContent: "center" }}>
        <Link
          color="inherit"
          to="/user"
          style={{ fontSize: !small ? 14 : "default" }}
        >
          Orders
        </Link>
        <Typography
          color="text.primary"
          sx={{ fontSize: !small ? 14 : "default" }}
        >
          Order Details
        </Typography>
        <RefreshIcon
          sx={{ cursor: "pointer", fontSize: !small ? 16 : "default" }}
          onClick={handleRefresh}
        />
      </Breadcrumbs>
      <div id="export" style={{ height: "100%" }}>
        <Stack
          direction="row"
          sx={{ backgroundColor: "#ffffff" }}
          p={1}
          justifyContent={"space-between"}
          gap={!small ? 2 : 0}
        >
          <Typography variant={!small ? "body2" : "h6"} mr={2}>
            <strong>Ref:</strong> {userData?.customer.refName}
          </Typography>
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          height="100%"
          mt={0.5}
          sx={{ backgroundColor: "#ffffff" }}
        >
          <Stack p={1} width="50%" height="100%" fontSize="14px">
            <Typography
              variant="h6"
              fontSize={!small ? 14 : 16}
              fontWeight={"bold"}
            >
              Compleet:
            </Typography>
            <Divider flexItem />
            <Box sx={{ fontSize: !small ? 13 : "default" }}>
              {userData?.order.compleet1.spoor}.Spoor x{" "}
              {userData?.order.compleet1.w} x {userData?.order.compleet1.h}
            </Box>
            {userData?.order.compleet2.w !== "" &&
              userData?.order.compleet2.h !== "" &&
              userData?.order.compleet2.spoor !== "" && (
                <Box sx={{ fontSize: !small ? 13 : "default" }}>
                  {userData?.order.compleet2.spoor}.Spoor x{" "}
                  {userData?.order.compleet2.w} x {userData?.order.compleet2.h}
                </Box>
              )}
            {userData?.order.compleet3.w !== "" &&
              userData?.order.compleet3.h !== "" &&
              userData?.order.compleet3.spoor !== "" && (
                <Box sx={{ fontSize: !small ? 13 : "default" }}>
                  {userData?.order.compleet3.spoor}.Spoor x{" "}
                  {userData?.order.compleet3.w}x {userData?.order.compleet3.h}
                </Box>
              )}
            {userData?.order.compleet4.w !== "" &&
              userData?.order.compleet4.h !== "" &&
              userData?.order.compleet4.spoor !== "" && (
                <Box sx={{ fontSize: !small ? 13 : "default" }}>
                  {userData?.order.compleet4.spoor}.Spoor x{" "}
                  {userData?.order.compleet4.w}x {userData?.order.compleet4.h}
                </Box>
              )}
            {userData?.order.compleet5.w !== "" &&
              userData?.order.compleet5.h !== "" &&
              userData?.order.compleet5.spoor !== "" && (
                <Box sx={{ fontSize: !small ? 13 : "default" }}>
                  {userData?.order.compleet5.spoor}.Spoor x{" "}
                  {userData?.order.compleet5.w}x {userData?.order.compleet5.h}
                </Box>
              )}
            {userData?.order.compleet6.w !== "" &&
              userData?.order.compleet6.h !== "" &&
              userData?.order.compleet6.spoor !== "" && (
                <Box sx={{ fontSize: !small ? 13 : "default" }}>
                  {userData?.order.compleet6.spoor}.Spoor x{" "}
                  {userData?.order.compleet6.w}x {userData?.order.compleet6.h}
                </Box>
              )}
            {userData?.order.compleet7.w !== "" &&
              userData?.order.compleet7.h !== "" &&
              userData?.order.compleet7.spoor !== "" && (
                <Box sx={{ fontSize: !small ? 13 : "default" }}>
                  {userData?.order.compleet7.spoor}.Spoor x{" "}
                  {userData?.order.compleet7.w}x {userData?.order.compleet7.h}
                </Box>
              )}
            {userData?.order.compleet8.w !== "" &&
              userData?.order.compleet8.h !== "" &&
              userData?.order.compleet8.spoor !== "" && (
                <Box sx={{ fontSize: !small ? 13 : "default" }}>
                  {userData?.order.compleet8.spoor}.Spoor x{" "}
                  {userData?.order.compleet8.w}x {userData?.order.compleet8.h}
                </Box>
              )}
          </Stack>
          <Stack p={1} width="50%" height="100%" fontSize="14px">
            <Typography
              variant="h6"
              fontSize={!small ? 14 : 16}
              fontWeight={"bold"}
            >
              Glasses:
            </Typography>
            <Divider flexItem />
            <Box sx={{ fontSize: !small ? 13 : "default" }}>
              {userData?.order.cam1.amount}x {userData?.order.cam1.w}x{" "}
              {userData?.order.cam1.h} {userData?.order.cam1?.note}
            </Box>
            {userData?.order.cam2.w !== "" && userData?.order.cam2.h !== "" && (
              <Box sx={{ fontSize: !small ? 13 : "default" }}>
                {userData?.order.cam2.amount}x {userData?.order.cam2.w}x{" "}
                {userData?.order.cam2.h} {userData?.order.cam2?.note}
              </Box>
            )}
            {userData?.order.cam3.w !== "" && userData?.order.cam3.h !== "" && (
              <Box sx={{ fontSize: !small ? 13 : "default" }}>
                {userData?.order.cam3.amount}x{userData?.order.cam3.w}x{" "}
                {userData?.order.cam3.h} {userData?.order.cam3?.note}
              </Box>
            )}
            {userData?.order.cam4.w !== "" && userData?.order.cam4.h !== "" && (
              <Box sx={{ fontSize: !small ? 13 : "default" }}>
                {userData?.order.cam4.amount}x {userData?.order.cam4.w}x{" "}
                {userData?.order.cam4.h} {userData?.order.cam4?.note}
              </Box>
            )}
            {userData?.order.cam5.w !== "" && userData?.order.cam5.h !== "" && (
              <Box sx={{ fontSize: !small ? 13 : "default" }}>
                {userData?.order.cam5.amount}x {userData?.order.cam5.w}x{" "}
                {userData?.order.cam5.h} {userData?.order.cam5?.note}
              </Box>
            )}
            {userData?.order.cam6.w !== "" && userData?.order.cam6.h !== "" && (
              <Box sx={{ fontSize: !small ? 13 : "default" }}>
                {userData?.order.cam6.amount}x {userData?.order.cam6.w}x{" "}
                {userData?.order.cam6.h} {userData?.order.cam6?.note}
              </Box>
            )}
            {userData?.order.cam7.w !== "" && userData?.order.cam7.h !== "" && (
              <Box sx={{ fontSize: !small ? 13 : "default" }}>
                {userData?.order.cam7.amount}x {userData?.order.cam7.w}x{" "}
                {userData?.order.cam7.h} {userData?.order.cam7?.note}
              </Box>
            )}
            {userData?.order.cam8.w !== "" && userData?.order.cam8.h !== "" && (
              <Box sx={{ fontSize: !small ? 13 : "default" }}>
                {userData?.order.cam8.amount}x {userData?.order.cam8.w}x{" "}
                {userData?.order.cam8.h} {userData?.order.cam8?.note}
              </Box>
            )}
          </Stack>
        </Stack>
        <Stack
          sx={{ backgroundColor: "#ffffff" }}
          p={1}
          my={0.5}
          gap={1}
          direction="row"
          alignItems="flex-start"
          justifyContent="space-between"
          fontSize="14px"
        >
          <Stack width="50%">
            <Box sx={{ fontSize: !small ? 13 : "default" }}>
              {userData?.order.ts}x T.S
            </Box>
            <Box sx={{ fontSize: !small ? 13 : "default" }}>
              {userData?.order.hv}x H.V
            </Box>
          </Stack>
          {userData?.order.colorCode.value && (
            <Stack width="50%">
              <Typography variant="h6" fontSize={!small ? 14 : 16}>
                <strong>Color:</strong> {userData?.order.colorCode.value}
              </Typography>
            </Stack>
          )}
        </Stack>
        <Stack
          sx={{ backgroundColor: "#ffffff" }}
          alignItems="flex-start"
          justifyContent="space-between"
          width="100%"
          my={0.5}
        >
          <Typography sx={{ fontSize: !small ? 14 : 16, padding: 1 }}>
            <strong>Price:</strong> {userData?.order.price}
          </Typography>
        </Stack>
        <Stack
          sx={{ backgroundColor: "#ffffff" }}
          alignItems="flex-start"
          justifyContent="space-between"
          fontSize="14px"
          width="100%"
        >
          <Typography sx={{ fontSize: !small ? 14 : 16, padding: 1 }}>
            <strong>Note:</strong> {userData?.order.note}
          </Typography>
        </Stack>
        <Stack
          sx={{ backgroundColor: "#ffffff" }}
          direction="row"
          alignItems="center"
          my={0.5}
          spacing={30}
        >
          <Typography sx={{ fontSize: !small ? 14 : 16, padding: 1 }}>
            <strong>Client:</strong> {userData?.customer.clientName}
          </Typography>
          <Typography
            fontWeight={600}
            color="tomato"
            sx={{ fontSize: !small ? 14 : 16, padding: 1 }}
          >
            {userData?.customer.isGripp ? "GRIPP" : ""}
          </Typography>
        </Stack>
        <Stack
          sx={{ backgroundColor: "#ffffff" }}
          my={0.5}
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Grid sx={{ p: 1 }}>
            <Typography
              variant="h6"
              fontSize={!small ? 14 : 16}
              fontWeight={"bold"}
            >
              Address:
            </Typography>
            <Divider flexItem />
            <Typography fontSize={!small ? 13 : 16}>
              {userData?.customer.street.toUpperCase()}{" "}
              {userData?.customer.houseNumber} {userData?.customer.postcode}{" "}
              {userData?.customer.city}
            </Typography>
            <Typography fontSize={!small ? 13 : 16}>
              {userData?.customer.city.toUpperCase()}{" "}
            </Typography>
          </Grid>
        </Stack>
        <Stack
          direction={small ? "row" : "column"}
          alignItems="flex-start"
          spacing={0.5}
          sx={{ backgroundColor: "#ffffff" }}
        >
          <Stack
            alignItems="flex-start"
            justifyContent="space-between"
            width={!small ? "100%" : "50%"}
            fontSize="14px"
          >
            <Grid container sx={{ p: 1 }}>
              <Typography variant="h6" fontSize={!small ? 14 : 16}>
                <strong>Client note:</strong> {userData?.customer.userNote}
              </Typography>
            </Grid>
          </Stack>
          <Stack
            sx={{ backgroundColor: "#ffffff" }}
            width={!small ? "100%" : "50%"}
            fontSize="14px"
          >
            <Grid sx={{ p: 1 }}>
              <Typography width="100%" fontSize={!small ? 14 : 16}>
                <strong>Email:</strong> {userData?.customer.email}
              </Typography>
              <Typography width="100%" fontSize={!small ? 14 : 16}>
                <strong>Phone Number:</strong> {userData?.customer.phoneNumber}
              </Typography>
            </Grid>

            <Typography width="100%" fontSize={!small ? 14 : 16}></Typography>
          </Stack>
        </Stack>
      </div>

      <Stack
        direction="row"
        alignItems="center"
        spacing={!small ? 0.5 : 1}
        justifyContent="flex-end"
        mt={1}
      >
        {userRole === "admin" && (
          <Button
            variant="contained"
            color="success"
            onClick={() => navigate(`/admin/editnormalform/${id}`)}
          >
            Edit
          </Button>
        )}
        {userRole === "admin" && (
          <Button
            size={!small ? "small" : "medium"}
            variant="contained"
            color="success"
            onClick={handleX}
            disabled={userData?.order.isx || xClicked}
          >
            X Control
          </Button>
        )}
        {(userRole === "administratie" || userRole === "admin") && (
          <Button
            variant="outlined"
            onClick={exportPDF}
            size={!small ? "small" : "medium"}
          >
            Export as PDF
          </Button>
        )}
        {(userRole === "administratie" || userRole === "admin") && (
          <Button
            variant="contained"
            size={!small ? "small" : "medium"}
            onClick={handleFinanced}
            color="success"
            disabled={userData?.order.isfinanced || isFinanced}
          >
            {userData?.order.isfinanced ? "F Approved" : "F Approve"}
          </Button>
        )}
        {(userRole === "user" || userRole === "admin") && (
          <Button
            variant="contained"
            size={!small ? "small" : "medium"}
            onClick={handleCompleted}
            color="success"
            disabled={userData?.order.iscompleted || isFinished}
          >
            Complete Order
          </Button>
        )}
      </Stack>
      <Snackbar
        open={notification.isOpen}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {notification.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
};
